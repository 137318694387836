/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
				/* Menu fixed
 				************************************/
				if (!Modernizr.touchevents) { 
					var menu = $('.content-top-menu');
					// top menu fixed on scroll
					$(window).scroll(function() {
						// 100 = The point you would like to fade the nav in.
						if ($(window).scrollTop() > 20) {
							//$(menu).addClass('fixed');
							$('.humburger-menu').addClass('humburger-fixed');
						} else {
							$(menu).removeClass('fixed');
							$('.humburger-menu').removeClass('humburger-fixed');
						}
					});
				}

				/************************
				Dropdown menu
				************************/
				$('.top-menu ul.menu > li > ul.sub-menu > li.menu-item-has-children > ul.sub-menu').each(function() {
					var menu = this;
					//$(menu).append("<div class='down-navigate'><a href='#' title='Next'><i class='fa fa-angle-down' aria-hidden='true'></i></a></div>");
					//$(menu).append("<div class='top-navigate'><a href='#' title='Prew'><i class='fa fa-angle-up' aria-hidden='true'></i></a></div>");
					//aggiungo il row-menu ogni 2 li
					var subcontainer = $('<div class="row-menu"></div>');
					var i = 0;
					$(this).find(' > li.menu-item-has-children').each(function() {
						if (i % 2 === 0) {
							//console.log(i);
							$(subcontainer).html(this).clone().appendTo(menu);
						} else {
							$(menu).find(".row-menu").last().append(this);							
						}
						i++;
					});
					$(this).find('.row-menu').last().addClass('last-row');
					$(this).find('.row-menu').first().addClass('first-row');
					$(this).find('.row-menu').first().addClass('current');
					$(this).find('.row-menu').parent('.sub-menu').append("<div class='down-navigate'><a href='#' title='Next'><i class='fa fa-angle-down' aria-hidden='true'></i></a></div>");
					$(this).find('.row-menu').parent('.sub-menu').append("<div class='top-navigate'><a href='#' title='Prew'><i class='fa fa-angle-up' aria-hidden='true'></i></a></div>");
					$(this).find('.row-menu').parent('.sub-menu').append("<div class='btn-all-product'><a href='#' class='btn btn-readmore' title=''>Visualizza tutti i prodotti di questa categoria</a></div>");
					$(this).find('.row-menu').parent('.sub-menu').append("<a href='#' class='close-button-menu' title=''><i class='fa fa-times' aria-hidden='true'></i></a>");
					$('.top-navigate a').addClass('disable-link');
				});

				//pulsante per mostrare tutti i menu
				$('.btn-all-product a').click(function(e) {
					e.preventDefault();
					var linkMenu = $(this);
					$(this).addClass('disable-link');
					var currentMenu = 	$(this).parent().parent('.sub-menu');
					$(currentMenu).find('.row-menu').addClass('current');
					$(currentMenu).find('.top-navigate a').addClass('disable-link');
					$(currentMenu).find('.down-navigate a').addClass('disable-link');
					$(currentMenu).find('.row-menu').show();
					$(currentMenu).addClass('open-submenu');			
				});
				//chiudo il menu aperto
				$('.close-button-menu').click(function(e) {
					e.preventDefault();
					var currentMenu = 	$(this).parent('.sub-menu');
					$(currentMenu).removeClass('open-submenu');	
					//$(currentMenu).find('.top-navigate a').removeClass('disable-link');
					$(currentMenu).find('.down-navigate a').removeClass('disable-link');
					$(currentMenu).find('.btn-all-product a').removeClass('disable-link');
				});
				//pulsante scroll down
				$('.down-navigate a').click(function(e) {
					e.preventDefault();
					var linkMenu = $(this);
					var currentMenu = 	$(this).parent().parent('.sub-menu');
					var rows = $(currentMenu).find('.row-menu.current');
					var count = $(rows).length;
					console.log('righe ' + count);
					$(currentMenu).find('.top-navigate a').removeClass('disable-link');
					$(currentMenu).addClass('menuClick');
					var currentRow = $(currentMenu).find('.row-menu.current');
					$(currentRow).removeClass('current').hide().next(".row-menu").show().addClass('current');
					if ($(currentMenu).find('.row-menu.current').hasClass('last-row')) {
						$(linkMenu).addClass('disable-link');
					}				
				});

				//pulsante top scroll
				$('.top-navigate a').click(function(e) {
					e.preventDefault();
					var linkMenu = $(this);
					var currentMenu = 	$(this).parent().parent('.sub-menu');
					var rows = $(currentMenu).find('.row-menu.current')
					var count = $(rows).length;
					console.log('righe ' + count);
					$(currentMenu).find('.down-navigate a').removeClass('disable-link');
					$(currentMenu).addClass('menuClick');
					var currentRow = $(currentMenu).find('.row-menu.current')
					$(currentRow).removeClass('current').hide().prev(".row-menu").show().addClass('current');
					if ($(currentMenu).find('.row-menu.current').hasClass('first-row')) {
						$(linkMenu).addClass('disable-link');
					}				
				});
				//disable link on single row-menu
				$('ul.sub-menu div.row-menu.last-row.first-row.current').parent('.sub-menu').addClass('disable-arrow');
				$('.sub-menu.disable-arrow').find('.down-navigate a').addClass('disable-link');


				/*$('ul.menu > .menu-item-has-children')
					.mouseover(function() {
					clearTimeout(this.timer)
					$(this).children('ul.sub-menu').addClass('animation-top');
				})
					.mouseleave(function() {
					this.timer = setTimeout(function() {
						$(this).children('ul.sub-menu').removeClass('animation-top');
					}.bind(this), 600);
				});
				
				$('ul.menu > .menu-item-has-children > .sub-menu > .menu-item-has-children')
					.mouseover(function() {
					clearTimeout(this.timer)
					$(this).children('ul.sub-menu').addClass('animation-top');
				})
					.mouseleave(function() {
					this.timer = setTimeout(function() {
						$(this).children('ul.sub-menu').removeClass('animation-top');
					}.bind(this), 600);
				});
				*/
				
				$("ul.menu > .menu-item-has-children").hoverIntent({    
    sensitivity: 2, // number = sensitivity threshold (must be 1 or higher)    
    interval: 180,  // number = milliseconds for onMouseOver polling interval    
    timeout: 600,   // number = milliseconds delay before onMouseOut    
    over:function(){
        $(this).children('ul.sub-menu').addClass('animation-top');
    },
    out: function(){
        $(this).children('ul.sub-menu').removeClass('animation-top');
    }
				});
				
				$("ul.menu > .menu-item-has-children > .sub-menu > .menu-item-has-children").hoverIntent({    
    sensitivity: 2, // number = sensitivity threshold (must be 1 or higher)    
    interval: 180,  // number = milliseconds for onMouseOver polling interval    
    timeout: 600,   // number = milliseconds delay before onMouseOut    
    over:function(){
        $(this).children('ul.sub-menu').addClass('animation-top');
    },
    out: function(){
        $(this).children('ul.sub-menu').removeClass('animation-top');
    }
				});


				/************************
				Humburger-menu
				************************/
				$(".navigation-humburger-menu").click(function() {
					$('body').toggleClass("open-humburger-menu");
				});

				$("#menu-top-menu > .menu-item-has-children > a").click(function(e) {
					e.preventDefault();
					$(this).parents('li').find(' > .sub-menu').slideToggle('80');
				});
				$("#menu-top-menu > .menu-item-has-children > .sub-menu > .menu-item-has-children > a").click(function(e) {
					e.preventDefault();
					$(this).parent().find('> .sub-menu').slideToggle('80');
				});
				$("#menu-top-menu > .menu-item-has-children > .sub-menu > .menu-item-has-children > .sub-menu > .menu-item-has-children > a").click(function(e) {
					e.preventDefault();
					$(this).parent().find('> .sub-menu').slideToggle('80');
				});
				// Search
				// -------------------------------------------------------
				$(".button_search").click(function(e) {
					e.preventDefault();
					$('.content-top-search-form').toggleClass("search-open");
				});

				/* Swiper carousel
 				************************************/
				var swiper = new Swiper('.gallery-carousel', {
					pagination: '.swiper-pagination',
					nextButton: '.swiper-button-next',
					prevButton: '.swiper-button-prev',
					slidesPerView: 4,
					//centeredSlides: true,
					paginationClickable: true,
					spaceBetween: 30,
					breakpoints: {
						768: {
							slidesPerView: 2,
							spaceBetween: 30
						},
						640: {
							slidesPerView: 2,
							spaceBetween: 20
						},
						320: {
							slidesPerView: 2,
							spaceBetween: 10
						}
					}
				});
				/* Swiper carousel footer
 				************************************/
				var swiper2 = new Swiper('.carousel-footer', {
					//pagination: '.swiper-pagination',
					nextButton: '.swiper-button-next-footer',
					prevButton: '.swiper-button-prev-footer',
					slidesPerView: 3,
					centeredSlides: false,
					paginationClickable: true,
					spaceBetween: 30,
					autoplay: 2500,
					autoplayDisableOnInteraction: false,
					breakpoints: {
						768: {
							slidesPerView: 2,
							spaceBetween: 30
						},
						640: {
							slidesPerView: 2,
							spaceBetween: 20
						},
						320: {
							slidesPerView: 1,
							spaceBetween: 10
						}
					}
				});

				/************************
				Scroll top
					************************/

				$(window).scroll(function() {
					if ($(this).scrollTop() > 250) {
						$('.scrolltotop').fadeIn();
					} else {
						$('.scrolltotop').fadeOut();
					}
				});
				$('.scrolltotop').click(function() {
					$("html, body").animate({
						scrollTop: 0
					}, 800);
					return false; 
				});

				/************************
				Accordion
				************************/
				function accordionCustom() {
					$('.collapse').on('shown.bs.collapse', function(){
						$(this).parent().find(".glyphicon-plus").removeClass("glyphicon-plus").addClass("glyphicon-minus");
					}).on('hidden.bs.collapse', function(){
						$(this).parent().find(".glyphicon-minus").removeClass("glyphicon-minus").addClass("glyphicon-plus");
					});
				}
				accordionCustom();

				/************************
				Tabs
				************************/
				$('.nav-tabs > li > a').click(function (event) {
					event.preventDefault();
					$(this).tab('show');
				});
				$('.nav-tabs-line > li > a').click(function (event) {
					event.preventDefault();
					$(this).tab('show');
				});


				/************************
				Lazy images
				************************/

				$('.lazy').Lazy({
					// your configuration goes here
					scrollDirection: 'vertical',
					effect: 'fadeIn',
					visibleOnly: true,
					onError: function(element) {
						console.log('error loading ' + element.data('src'));
					}
				});
				/************************
				Preload
				************************/
				$(".animsition").animsition({
					inClass: 'fade-in',
					outClass: 'fade-out',
					inDuration: 1500,
					outDuration: 800,
					linkElement: '.animsition-link',
					// e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
					loading: true,
					loadingParentElement: 'body', //animsition wrapper element
					loadingClass: 'animsition-loading',
					//loadingInner: '', // e.g '<img src="loading.svg" />'
					timeout: false,
					timeoutCountdown: 5000,
					onLoadEvent: true,
					browser: [ 'animation-duration', '-webkit-animation-duration'],
					// "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
					// The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
					overlay : false,
					overlayClass : 'animsition-overlay-slide',
					overlayParentElement : 'body',
					transition: function(url){ window.location.href = url; }
				});
				/* Parallax enllax
				************************************/
				if (!Modernizr.touchevents) {  
					//$('.slide-parallax').enllax(); 
					$(window).enllax({

						//default values

						// type can be defined as it is background parallax scrolling element or foreground scrolling element.
						//type: 'background',  // another value for type is 'foreground'.

						ratio: 0.1,  // multiplier for scrolling speed. Less is slower. Default: '0'.

						direction: 'vertical' // another value for direction is 'horizontal'.

					});
				}

				/* video modal
				************************************/

				$(".content-button .btn-video").click(function (event) {
					event.preventDefault();
					var theModal = $(this).data("target"),
									videoSRC = $(this).attr("data-video"),
									videoSRCauto = videoSRC + "?html5=1&autoplay=1";
					$(theModal + ' iframe').attr('src', videoSRC);
					$(theModal + ' button.close').click(function () {
						$(theModal + ' iframe').attr('src', videoSRC);
					});
				});
				/* viewportChecker
     		************************************/
				if (!Modernizr.touchevents) { 
					//amimate fade
					jQuery('.fade-scroll').addClass("hidden-scroll").viewportChecker();
				}

				//animate number
				jQuery('.content-number-block .animate-count-block').addClass("hidden-scroll").viewportChecker({
					callbackFunction: function(elem, action) {
						$('.content-number-block .timer').countTo();
					}
				});
				jQuery('#content-number .animate-count').addClass("hidden-scroll").viewportChecker({
					callbackFunction: function(elem, action) {
						$('#content-number .timer').countTo();
					}
				});
				//***************************************************
				//  animate progress bar
				$.fn.animateBar = function() {
					$.each($('.progress-bar'), function() {
						$(this).css('width', $(this).attr('aria-valuenow') + '%');
					});
				};
				jQuery(document).ready(function() {
					jQuery('.animate-bar').viewportChecker({
						offset: 100,
						callbackFunction: function(elem, action) {
							jQuery('.progress').animateBar();
						}
					});
				});
				//***************************************************
				//  blank link external
				$('a').each(function() {
					var a = new RegExp('/' + window.location.host + '/');
					if (!a.test(this.href)) {
						$(this).attr("target","_blank");
					}
				});

				//***************************************************
				//  video modal


				$.each($('#container-post-type object'), function (index, value) { 
					console.log(index + ':' + $(value).text()); 
				});

			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page

			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
